import React, { useState, useEffect } from 'react';

export default props => {
    const { apiUrl } = window['runConfig'];
    const key = localStorage.getItem("applicantKey") || '';
    const [SignatureCheck, setSignatureCheck] = useState(false);
    const [validationError, setValidationError] = useState(false);
    const [noVehicle, setNoVehicle] = useState(false);
    const appData = props.appData;
    useEffect(() => {
        setNoVehicle(appData.noVehicle || false);
        setSignatureCheck(appData.noVehicleApproved || false);
    }, [appData])
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const submit = e => {
        e.preventDefault();
        props.setLoading(true);
        if (!noVehicle) {
            props.setLoading(false);
            props.setStep(7);
            const request = {
                key: key,
                signatureApproved: SignatureCheck,
                noVehicle: noVehicle
            }
            fetch(`${apiUrl}/api/Career/SignNoVehicle`, {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            }).then(response => {
                return response.json()
            }).then(response => {
                if (response.success) {
                    props.setStep(7)
                }
                props.setLoading(false);
            }).catch(err => {
                console.error(err);
                props.setLoading(false);
            })
        }
        else {
            if (!SignatureCheck) {
                setValidationError(true);
                props.setLoading(false);
                return;
            } else {
                setValidationError(false);
            }
        }
        const request = {
            key: key,
            signatureApproved: SignatureCheck,
            noVehicle: noVehicle
        }
        fetch(`${apiUrl}/api/Career/SignNoVehicle`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then(response => {
            return response.json()
        }).then(response => {
            if (response.success) {
                props.setStep(7)
            }
            props.setLoading(false);
        }).catch(err => {
            console.error(err);
            props.setLoading(false);
        })
    }

    return (
        <form action="/" method="post" onSubmit={(e) => submit(e)}>
            <div className="form">
                <div className="formrow">
                    <label>Check the box below if you do not own a vehicle. Otherwise, click next.</label>
                    <label>I do not own a vehicle: <input type="checkbox" checked={noVehicle} onChange={(e) => setNoVehicle(noVehicle ? false : true)} /></label>
                </div>
                {noVehicle ? (
                    <div>
                        <div>
                            <label> This agreement is written to state that I, <b>{appData.firstName} {appData.lastName}</b>, have no vehicle, nor auto insurance in my name,
                                therefore in compliance with Pennsylvania State Regulations and Office of Long Term Living, I will not be transporting Participates/Consumers IN 
                                ANY VEHICLE; including those of others. Transportation procured for or by the participate is permissible to accompany a participant/consumer using
                                public transportation or paid vehicles of a participant/consumer that is operated by the policy holder or outside supports.</label>
                            <br/><br/>
                            <label> Caregivers transporting or running errands for a participant/consumer without appropriate
                                documentation/ notification provided to Ageless Senior in Home Care, Inc is in a direct violation of this
                                policy and are grounds for immediate termination and without the possibility of rehire in the future.</label>
                            <br />
                        </div>
                        <div className="formrow">
                            <label>Check to sign:  <input type="checkbox" checked={SignatureCheck} onChange={(e) => setSignatureCheck(SignatureCheck ? false : true)} /></label>
                            {validationError ? (
                                <div>You must appprove this.</div>
                            ) : null}
                        </div>
                    </div>
                ) : null }
            </div>
            <input type="button" value="Back" onClick={() => props.setStep(5)} style={{ marginRight: "12px" }} />
            <input type="submit" value="Next" style={{ marginRight: "12px" }} />
        </form>
    )

}