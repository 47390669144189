import React, { useState, useEffect, useRef } from 'react'
import "../styles/form.css";
import SignatureCanvas from 'react-signature-canvas';


export default props => {
    const { apiUrl } = window['runConfig'];
    const appData = props.appData;
    const [validationError, setValidationError] = useState(false);
    const [SignatureCheck, setSignatureCheck] = useState(false);
    const [Sunday, setSunday] = useState("")
    const [Monday, setMonday] = useState("")
    const [Tuesday, setTuesday] = useState("")
    const [Wednesday, setWednesday] = useState("")
    const [Thursday, setThursday] = useState("")
    const [Friday, setFriday] = useState("")
    const [Saturday, setSaturday] = useState("")
    useEffect(() => {
        setSunday(appData.sunday || '');
        setMonday(appData.monday || '');
        setTuesday(appData.tuesday || '');
        setWednesday(appData.wednesday || '');
        setThursday(appData.thursday || '');
        setFriday(appData.friday || '');
        setSaturday(appData.saturday || '');
        setSignatureCheck(appData.hoursSignatureApproved || false);
    }, [appData])
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const submit = (e)=> {
        e.preventDefault();
        props.setLoading(true);
        if (!SignatureCheck) {
            setValidationError(true);
            props.setLoading(false);
            return;
        } else {
            setValidationError(false);
        }
        if (props.appData.hoursSignatureApproved) {
            props.setStep(6);
        }
        const request = {
            appKey: localStorage.getItem("applicantKey") || '',
            sunday: Sunday,
            monday: Monday,
            tuesday: Tuesday,
            wednesday: Wednesday,
            thursday: Thursday,
            friday: Friday,
            saturday: Saturday,
            signatureApproved: SignatureCheck
        }
        fetch(`${apiUrl}/api/Career/SaveJobHours`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then(response => {
            return response.json()
        }).then(response => {
            if (response.success) {
                props.setStep(5);
            }
            props.setLoading(false);
        }).catch(err => {
            console.error(err);
            props.setLoading(false);
        })
    }
    return (
        <div>
            <p>
                Schedules are created 2 weeks out at a time and are completed no later than Monday. For example
                schedule is created on Monday 6/25/2018 for 7/8/2018 through 7/14/2018. Any changes to week
                7/8/2018 through 7/18/2018 will have to be made prior to 6/ 25/ 2018.
            </p>
            <p>
                The schedule will be sent by Friday through the automatic system. Each employee is to respond stating
                they received their schedule. The schedule will be the employees schedule shifts and any changes will
                need to be approved, but changes are <u><b>NOT</b></u> guaranteed. If schedule changes are not possible each
                employee will be responsible for their shift or will be considered a call off or a no show depending on if
                the caregiver follows the call off policy. If the caregiver calls off they will be subjected to the disciplinary
                action of the policy. Medical excuses will be acceptable, but proof must be given <b>NO</b> exceptions.
            </p>
            <p>
                It is the employee's responsibility to find reliable transportation and child care to make it to their shifts.
                Employees will give their hours of availability during the orientation upon hire and will remain the hours of
                availability for at least up to 6 months unless under emergency circumstances. Once hours have been
                changed Ageless Senior Care, Inc. cannot guarantee new placement with another consumer.
            </p>
            <form action="/" method="post" onSubmit={(e) => submit(e)}>
                <div className="form">
                    <label>Please list the hours you are available on the following dates: <span className="required">*</span></label>
                    <div className="formrow">
                        <label>Sunday   <input type="text" value={Sunday} placeholder="9AM-5PM" onChange={e => setSunday(e.target.value)} required /></label>
                        <label>Monday   <input type="text" value={Monday} placeholder="9AM-5PM" onChange={e => setMonday(e.target.value)} required/></label>
                        <label>Tuesday   <input type="text" value={Tuesday} placeholder="9AM-5PM" onChange={e => setTuesday(e.target.value)} required/></label>
                        <label>Wednesday   <input type="text" value={Wednesday} placeholder="9AM-5PM" onChange={e => setWednesday(e.target.value)} required/></label>
                        <label>Thursday   <input type="text" value={Thursday} placeholder="9AM-5PM" onChange={e => setThursday(e.target.value)} required/></label>
                        <label>Friday   <input type="text" value={Friday} placeholder="9AM-5PM" onChange={e => setFriday(e.target.value)} required/></label>
                        <label>Saturday   <input type="text" value={Saturday} placeholder="9AM-5PM" onChange={e => setSaturday(e.target.value)} required/></label>
                    </div>
                    <div className="formrow">
                        <label>Check to sign:  <input type="checkbox" checked={SignatureCheck} onChange={(e) => setSignatureCheck(SignatureCheck ? false : true)} /></label>
                        {validationError ? (
                            <div>You must appprove this.</div>
                        ) : null}
                    </div>
                </div>
                <input type="button" value="Back" onClick={() => props.setStep(3)} style={{ marginRight: "12px" }} />
                <input type="submit" value="Next" style={{ marginRight: "12px" }} />
            </form>
        </div>
    )

}