import React from 'react';

import '../styles/loading.css';

const Loading = () => {
    return (
        <div className="appShroud">
            <div className="vcenter">
                <div className="lds-roller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    )
}
export default Loading;