import React, { useRef, useState, useEffect } from 'react';
import { Document, Page } from "react-pdf";

export default props => {
    const { apiUrl } = window['runConfig'];
    const appData = props.appData;
    const [path, setPath] = useState("");
    const [validationError, setValidationError] = useState(false);
    const [SignatureCheck, setSignatureCheck] = useState(false);
    const [hepVac, setHepVac] = useState(false);
    useEffect(() => {
        setSignatureCheck(appData.policyAgreementSignatureApproved || false);
        setHepVac(appData.hepVacAcceptance || false);
        props.setLoading(true);
        fetch(`${apiUrl}/api/Career/GetTemplatePDFPath`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ path: 'Acknowledgement_Of_Policies.pdf' })
        }).then(response => {
            return response.json()
        }).then(response => {
            if (response.success) {
                setPath(response.pdfStream);
            }
            props.setLoading(false);
        }).catch(err => {
            props.setLoading(false);
            console.log(err);
        })
    }, [apiUrl]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const next = () => {
        if (!SignatureCheck) {
            setValidationError(true);
            props.setLoading(false);
            return;
        } else {
            setValidationError(false);
        }
        if (props.appData.policyAgreementSignatureApproved) {
            props.setStep(15);
        }
        const request = {
            key: localStorage.getItem("applicantKey") || '',
            signatureApproved: SignatureCheck,
            hepVac: hepVac
        }
        fetch(`${apiUrl}/api/Career/SignPolicyAgreement`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then(response => {
            return response.json()
        }).then(response => {
            if (response.success) {
                props.setStep(15)
            }
            props.setLoading(false);
        }).catch(err => {
            console.error(err);
            props.setLoading(false);
        })
    }
    return (
        <div className="form">
            <div className="formrow">
                <label>Based on the PDF document you read previously, the form below in the viewer will be from this.</label>
                <label>This page is an acknowledgement of the Policies you read and agree to them. A question is attached:</label>
                <br/>
                <h4>Please confirm whether you want a Hep-B Vaccine:</h4>
                <label> Confirmation: <input type="checkbox" checked={hepVac} onChange={(e) => setHepVac(hepVac ? false : true)} /></label>
                <br/><br/>
            </div>
            <iframe
                id="iPDF"
                title="TerminationPDF"
                src={`data:application/pdf;base64,` + path}
                frameBorder={0}
                scrolling="no"
                allowFullScreen
                className="iFramePDF" />
            <br/><br/>
            <div className="formrow">
                <label>Check to sign:  <input type="checkbox" checked={SignatureCheck} onChange={(e) => setSignatureCheck(SignatureCheck ? false : true)} /></label>
                {validationError ? (
                    <div>You must appprove this.</div>
                ) : null}
            </div>
            <input type="button" value="Back" onClick={() => props.setStep(13)} style={{ marginRight: "12px" }} />
            <input type="button" value="Next" onClick={() => next()} style={{ marginRight: "12px" }} />
        </div>
    )
}