import React, {useRef, useState, useEffect} from 'react';

export default props => {
    const { apiUrl } = window['runConfig'];
    const appData = props.appData;
    const [validationError, setValidationError] = useState(false);
    const [SignatureCheck, setSignatureCheck] = useState(false);
    useEffect(() => {
        setSignatureCheck(appData.jobDescSignatureApproved || false);
    }, [appData])
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const next = () => {
        if (!SignatureCheck) {
            setValidationError(true);
            props.setLoading(false);
            return;
        } else {
            setValidationError(false);
        }
        if (props.appData.jobDescSignatureApproved)
        {
            props.setStep(6);
        }
        const request = {
            key: localStorage.getItem("applicantKey") || '',
            signatureApproved: SignatureCheck
        }
        fetch(`${apiUrl}/api/Career/SignJobDescription`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then(response => {
            return response.json()
        }).then(response => {
            if (response.success)
            {
                props.setStep(6)
            }
            props.setLoading(false);
        }).catch(err =>{
            console.error(err);
            props.setLoading(false);
        })
    }
    return (
        <div>
            <p style={{fontWeight: "bolder"}}>Home Health Aide Job Description</p>
            <p>
            Home health aides care for physically, mentally or emotionally ill or injured people  who  want to  remain in the comfort of their own home. You may be required to keep rooms, neat, help participants bathe, dress, help with incontinence care, help with range of motion, activities of daily living and instrumental activities of daily living (ADLs and IADLs). Participants may be recently discharged from the hospital and only need short-term care or require extensive care that friends and family are unable to provide. Hours may vary depending on the participants' needs, especially those who need around-the-clock care. Aides can work up to 40 hours per week, your preference of daylight, evening, weekends, or holidays. We will work with you to build a flexible schedule based upon the needs of both the aide and the participant.
            Workdays may consist of long hours on your feet and possible heavy lifting, as well as emotional stress of working closely with the sick and elderly. Aides should take every measure to use universal precautions.
            </p><p>
            Home health aides may go to the same patients' home for months or even years, but most aides work with several different patients, sometimes on the same day. They generally work alone with periodic visits from their supervisor. Aides are given detailed instructions on how to care for each individual and are expected to follow them accordingly . Aides are responsible for getting to and from shifts on their own.
            </p>
            <p>
            At times, participants may be unpleasant, disoriented, or irritable. Aides should be patient, tactful, friendly, honest, dependable, have good communication skills, and genuinely want to help those who need it. They should be willing to perform all required tasks no matter how unpleasant the participant may become.
            </p>
            <div className="formrow">
                <label>Check to sign:  <input type="checkbox" checked={SignatureCheck} onChange={(e) => setSignatureCheck(SignatureCheck ? false : true)} /></label>
                {validationError ? (
                    <div>You must appprove this.</div>
                ) : null}
            </div>
            <input type="button" value="Back" onClick={() => props.setStep(4)} style={{marginRight: "12px"}} />
            <input type="button" value="Next" onClick={() => next()} style={{marginRight: "12px"}} />
        </div>
    )
}