import React, { useState, useRef, useEffect } from 'react';

export default props => {
    const [validationError, setValidationError] = useState(false);
    const [option, setOption] = useState(1);
    const [date, setDate] = useState("");
    const [numberChoice, setNumberChoise] = useState(1);
    const [USCISNumber, setUSCISNumber] = useState("");
    const [AdmissionNumber, setAdmissionNumber] = useState("");
    const [PassportNumber, setPassportNumber] = useState("");
    const [CountryOfIssuance, setCountryOfIssuance] = useState("");
    const [SignatureCheck, setSignatureCheck] = useState(false);
    const { apiUrl } = window['runConfig'];
    const appData = props.appData;
    useEffect(() => {
        setOption(parseInt(appData.i9Option) || 1);
        setDate(appData.i9ExpireDate || '');
        setNumberChoise(parseInt(appData.i9NumberChoice) || 1);
        setUSCISNumber(appData.uscisNumber || '');
        setAdmissionNumber(appData.admissionNumber || '');
        setPassportNumber(appData.passportNumber || '');
        setCountryOfIssuance(appData.countryOfIssuance || '');
        setSignatureCheck(appData.i9SignatureApproved || false);
    }, [appData])
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const submit = e => {
        e.preventDefault();
        props.setLoading(true);
        if (!SignatureCheck) {
            setValidationError(true);
            props.setLoading(false);
            return;
        }
        const request = {
            key: localStorage.getItem("applicantKey") || '',
            signatureApproved: SignatureCheck,
            option: option,
            numberChoice: numberChoice,
            date: date,
            numberInput: option === 3 ? (USCISNumber) : (numberChoice === 1 ? USCISNumber : numberChoice === 2 ? AdmissionNumber : numberChoice === 3 ? PassportNumber : ""),
            countryOfIssuance: numberChoice === 3 ? CountryOfIssuance : ""
        };
        fetch(`${apiUrl}/api/Career/FillI9PDF`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then(response => {
            return response.json()
        }).then(response => {
            if (response.success) {
                props.setStep(9);
            }
            props.setLoading(false);
        }).catch(err => {
            console.error(err);
            props.setLoading(false);
        })
    }
    return (
        <div>
            <form action="/" method="post" onSubmit={(e) => submit(e)}>
                <div className="form">
                    <label>
                        I am aware that federal law provides for imprisonment and/or fines for false statements or use of false documents in connection with the completion of this form.
                    </label>
                    <div className="formrow">
                        <label>
                            I attest, under penalty of perjury, that I am (check one of the following):
                        </label>
                        <label>
                            <input type="radio" name="i9cit" checked={option === 1 ? true : false} onChange={(e) => setOption(1)} /> 
                            <span style={{marginLeft: "8px"}}>A citizen of the United States</span>
                        </label>
                        <label>
                            <input type="radio" name="i9cit" checked={option === 2 ? true : false} onChange={(e) => setOption(2)} /> 
                            <span style={{marginLeft: "8px"}}>A noncitizen national of the United States (See instructions)</span>
                        </label>
                        <label>
                            <input type="radio" name="i9cit" checked={option === 3 ? true : false} onChange={(e) => setOption(3)} /> 
                            <span style={{marginLeft: "8px"}}>A lawful peremanent resident</span>
                        </label>
                        <label>
                            <input type="radio" name="i9cit" checked={option === 4 ? true : false} onChange={(e) => setOption(4)} /> 
                            <span style={{marginLeft: "8px"}}>An alien authorized to work...</span>
                        </label>
                    </div>
                    <div className="formrow">
                        {parseInt(option) === 3 ? (
                            <div>
                                <label>Alien Registration Number/USCIS Number:</label>
                                <input type="text" value={USCISNumber} 
                                    style={{marginLeft: "8px"}}
                                    onChange={(e) => setUSCISNumber(e.target.value)} required />
                            </div>
                        ) : null}
                        {parseInt(option) === 4 ? 
                            <div>
                                <label>
                                    ... until: (expiration date, if applicable, mm/dd/yyy)
                                    <div>
                                        <input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
                                    </div>
                                </label>
                                {date !== `` ? 
                                    <div>
                                        <div>
                                            <label>Method of Authorization:</label>
                                        </div>
                                        <div>
                                            <label>
                                                <input type="radio" name="i9num" checked={numberChoice === 1 ? true : false} onClick={(e) => setNumberChoise(1)} /> Alien Registration Number/USCIS Number
                                            </label>
                                            {numberChoice === 1 ? (
                                                <input type="text" value={USCISNumber} onChange={(e) => setUSCISNumber(e.target.value)} required />
                                            ) : null}
                                         </div>
                                         <div>
                                            <label>
                                                <input type="radio" name="i9num" checked={numberChoice === 2 ? true : false} onClick={(e) => setNumberChoise(2)} /> Form I-94 Admission Number
                                            </label>
                                            {numberChoice === 2 ? (
                                                <input type="text" value={AdmissionNumber} onChange={(e) => setAdmissionNumber(e.target.value)} required />
                                            ) : null}
                                        </div>
                                        <div>
                                            <label>
                                                <input type="radio" name="i9num" checked={numberChoice === 3 ? true : false} onClick={(e) => setNumberChoise(3)} /> Foreign Passport Number
                                            </label>
                                            {numberChoice === 3 ? (
                                                <div>
                                                    <div>
                                                        <label>Passport Number Here:</label>
                                                        <input type="text" value={PassportNumber} onChange={(e) => setPassportNumber(e.target.value)} required />
                                                    </div>
                                                    <div>
                                                        <label>Country of Issuance:</label> 
                                                        <input type="text" value={CountryOfIssuance} onChange={(e) => setCountryOfIssuance(e.target.value)} required />
                                                    </div>
                                                </div>
                                            ): null}
                                        </div>
                                    </div>
                                    : null }
                            </div>
                            : null}
                        <div className="formrow">
                            <label>Check to sign:  <input type="checkbox" checked={SignatureCheck} onChange={(e) => setSignatureCheck(SignatureCheck ? false : true)} /></label>
                            {validationError ? (
                                <div>You must appprove this.</div>
                            ) : null}
                        </div>
                    </div>
                    <input type="button" value="Back" onClick={() => props.setStep(7)} style={{ marginRight: "12px" }} />
                    <input type="submit" value="Next" style={{ marginRight: "12px" }} />
                </div>
            </form>
        </div>
    )
}