import React from 'react';

import "../styles/header.css";

export default () => {
    return (
        <div>
            <div className="header">
                <a href="https://inhomecareexperts.com">
                    <img src="/images/AS-Logo-300x221_blue-bg.png" 
                        alt="Ageless Senior In Home Care, Inc."
                        className="logo" />
                </a>
            </div>
            <div className="navbar">
                <ul className="navigation">
                    <li>
                        <a href="https://inhomecareexperts.com">Home</a>
                    </li>
                    <li>
                        <a href="https://inhomecareexperts.com/about">About</a>
                    </li>
                    <li>
                        <a href="https://inhomecareexperts.com/services">Services</a>
                    </li>
                    <li>
                        <a href="/">Careers</a>
                    </li>
                    <li>
                        <a href="https://inhomecareepxerts.com/contact">Contact</a>
                    </li>
                    <li>
                        <a href="https://inhomecareexperts.com/referral-app">Referral App</a>
                    </li>
                </ul>
            </div>
        </div>
    )
}