import React, { useState, useEffect } from 'react';
import "../styles/form.css";

export default props => {
    const { apiUrl } = window['runConfig'];
    const appData = props.appData;
    const [refOneName, setRefOneName] = useState("");
    const [refOneNumber, setRefOneNumber] = useState("");
    const [refTwoName, setRefTwoName] = useState("");
    const [refTwoNumber, setRefTwoNumber] = useState("");
    useEffect(() => {
        setRefOneName(appData.refOneName || "");
        setRefOneNumber(appData.refOneNumber || "");
        setRefTwoName(appData.refTwoName || "");
        setRefTwoNumber(appData.refTwoNumber || "");
    }, [appData]) 
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const submit = (e) => {
        e.preventDefault();
        props.setLoading(true);
        const request = {
            key: localStorage.getItem("applicantKey") || '',
            refOneName: refOneName,
            refOneNumber: refOneNumber,
            refTwoName: refTwoName,
            refTwoNumber: refTwoNumber,
        }
        fetch(`${apiUrl}/api/Career/SaveReferences`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then(response => {
            return response.json()
        }).then(response => {
            if (response.success) {

                props.setStep(4);
            }
            props.setLoading(false);
        }).catch(err => {
            console.error(err);
            props.setLoading(false);
        })
    }
    return (
        <div>
            <form action="/" method="post" onSubmit={(e) => submit(e)}>
                <label>One profession reference and one personal reference are needed. List their name and number for each below</label>
                <div className="form">
                    <h4>Professional Reference:</h4>
                    <div className="formrow">
                        <label>
                            Name
                            <span className="required">*</span>
                        </label>
                        <input type="text" value={refOneName} onChange={e => setRefOneName(e.target.value)} required />
                    </div>
                    <div className="formrow">
                        <label>
                            Phone Number
                            <span className="required">*</span>
                        </label>
                        <input type="text" value={refOneNumber} onChange={e => setRefOneNumber(e.target.value)} required />
                    </div>
                    <h4>Personal Reference:</h4>
                    <div className="formrow">
                        <label>
                            Name
                            <span className="required">*</span>
                        </label>
                        <input type="text" value={refTwoName} onChange={e => setRefTwoName(e.target.value)} required />
                    </div>
                    <div className="formrow">
                        <label>
                            Phone Number
                            <span className="required">*</span>
                        </label>
                        <input type="text" value={refTwoNumber} onChange={e => setRefTwoNumber(e.target.value)} required />
                    </div>
                </div>

                <input type="button" value="Back" onClick={() => props.setStep(2)} style={{ marginRight: "12px" }} />
                <input type="submit" value="Next" style={{ marginRight: "12px" }} />
            </form>
        </div>
    )

}