import React, { Component } from 'react';

import Header from "./components/header";
import Application from "./components/application";

import './styles/global.css';

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <div>
        <Header />
        <div className="main">
          <Application />
        </div>
      </div>
    );
  }
}
