import React, { useRef, useState, useEffect } from 'react';
import { Document, Page } from "react-pdf";

export default props => {
    const { apiUrl } = window['runConfig'];
    const appData = props.appData;
    const [path, setPath] = useState("");
    const [validationError, setValidationError] = useState(false);
    const [SignatureCheck, setSignatureCheck] = useState(false);
    const [chkDownload, setChkDownload] = useState(false);
    let download = require('downloadjs');
    useEffect(() => {
        setSignatureCheck(appData.handbookSignatureApproved || false);
        props.setLoading(true);
        fetch(`${apiUrl}/api/Career/GetTemplatePDFPath`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ path: 'Handbook_Agreement.pdf' })
        }).then(response => {
            return response.json()
        }).then(response => {
            if (response.success) {
                setPath(response.pdfStream);
            }
            props.setLoading(false);
        }).catch(err => {
            props.setLoading(false);
            console.log(err);
        })
    }, [appData]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const next = () => {
        if (!SignatureCheck) {
            setValidationError(true);
            props.setLoading(false);
            return;
        } else {
            setValidationError(false);
        }
        if (props.appData.handbookSignatureApproved) {
            props.setStep(14);
        }
        const request = {
            key: localStorage.getItem("applicantKey") || '',
            signatureApproved: SignatureCheck
        }
        fetch(`${apiUrl}/api/Career/SignHandbookAgreement`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then(response => {
            return response.json()
        }).then(response => {
            if (response.success) {
                props.setStep(14)
            }
            props.setLoading(false);
        }).catch(err => {
            console.error(err);
            props.setLoading(false);
        })
    }
    const downloadHandbook = (e) => {
        e.preventDefault();
        props.setLoading(true);
        setChkDownload(true);
        fetch(`${apiUrl}/api/Career/GetHandbook`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => res.blob())
            .then(blob => {
                download(blob, "Employee Handbook.pdf");
                props.setLoading(false);
                setChkDownload(false);
            })
            .catch(err => {
                console.log("Unable to download file: " + err);
            })
    }
    return (
        <div>
            <div className="form">
                <h3>Download the Handdook here:</h3>
                <div>
                    <input type="button" value="Handbook" onClick={(e) => downloadHandbook(e)} />
                    {chkDownload ? 
                        <label>Download in process....</label>
                    : null }
                </div>
                <br /><br />
                <h3>Please only Sign the document below once you download the Handbook.</h3>
                <br />
                <iframe
                    id="iPDF"
                    title="TerminationPDF"
                    src={`data:application/pdf;base64,` + path}
                    frameBorder={0}
                    scrolling="no"
                    allowFullScreen
                    className="iFramePDF" />
                <div className="formrow">
                    <label>Check to sign:  <input type="checkbox" checked={SignatureCheck} onChange={(e) => setSignatureCheck(SignatureCheck ? false : true)} /></label>
                    {validationError ? (
                        <div>You must appprove this.</div>
                    ) : null}
                </div>
                <input type="button" value="Back" onClick={() => props.setStep(12)} style={{ marginRight: "12px" }} />
                <input type="button" value="Next" onClick={() => next()} style={{ marginRight: "12px" }} />
            </div>
        </div>
    )
}