import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom';
import Loading from "./loading";
import BasicInfo from "./basicinfo";
import Welcome from "./welcome";
import JobDescription from "./jobdescription";
import I9Info from "./i9info";
import I9Verification from "./i9verification";
//import references from "./references";
import TerminationAcceptance from "./terminationAcceptance";
import W4Info from "./w-4info";
import W4Verification from "./w-4verification";
import CompletionPage from "./CompletionPage";
import References from "./references";
import ScheduleChange from "./ScheduleChange";
import EmployeeAgreement from "./employeeAgreement";
import VehicleConfirmation from "./noVehicle";
import HandbookAgreement from "./handbookagreement";
import PolicyAgreement from "./policyAgreement";
import "../styles/form.css";

export default () => {
    const { apiUrl } = window['runConfig'];
    const [loading, setLoading] = useState(false);
    const [appData, setAppData] = useState({});
    const [step, setStep] = useState(1);
    const stepNames = [
        "Basic Information",
        "Welcome",
        "References",
        "Schedules",
        "Job Description",
        "Vehicle Confirmation",
        "Termination Checklist",
        "Form I-9 Information",
        "Form I-9 Verification",
        "Form W-4 Information",
        "Form W-4 Verification",
        "Employee Agreement",
        "Handbook Agreement",
        "Policy Agreement",
        "Completion Page"
    ];
    useEffect(() => {
        let appkey = localStorage.getItem("applicantKey");
        if (!appkey) {
            return
        }
        console.log(`Load ${appkey}`);
        setLoading(true);
        fetch(`${apiUrl}/api/Career/LoadApplication`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ appKey: appkey })
        }).then(response => {
            return response.json()
        }).then(response => {
            console.log(response);
            if (response.success) {
                setAppData(response.application)
                if (response.application.step > 0) {
                    setStep(response.application.step);
                }
            }
            setLoading(false);
        }).catch(err => {
            console.error(err);
            setLoading(false);
        })
    }, [apiUrl])
    return (
        <div>
            {loading ? (
                <Loading />
            ) : null}
            <h1>Apply Now</h1>
            <h2>Step {step} of {stepNames.length} - {stepNames[step - 1]}</h2>
            {step === 1 ? (
                <BasicInfo setStep={setStep} setLoading={setLoading} appData={appData} />
            ) : step === 2 ? (
                <Welcome setStep={setStep} setLoading={setLoading} />
            ) : step === 3 ? (
                <References appData={appData} setLoading={setLoading} setStep={setStep} />
            ) : step === 4 ? (
                <ScheduleChange appData={appData} setStep={setStep} setLoading={setLoading} />
            ) : step === 5 ? (
                <JobDescription setStep={setStep} appData={appData} setLoading={setLoading} />
            ) : step === 6 ? (
                <VehicleConfirmation setStep={setStep} appData={appData} setLoading={setLoading} />
            ) : step === 7 ? (
                <TerminationAcceptance setStep={setStep} setLoading={setLoading} appData={appData} />
            ) : step === 8 ? (
                <I9Info setStep={setStep} appData={appData} setLoading={setLoading} />
            ) : step === 9 ? (
                <I9Verification setStep={setStep} setLoading={setLoading} appData={appData} />
            ) : step === 10 ? (
                <W4Info setStep={setStep} appData={appData} setLoading={setLoading} />
            ) : step === 11 ? (
                <W4Verification setStep={setStep} setLoading={setLoading} appData={appData} />
            ) : step === 12 ? (
                <EmployeeAgreement appData={appData} setLoading={setLoading} setStep={setStep} />
            ) : step === 13 ? (
                <HandbookAgreement appData={appData} setLoading={setLoading} setStep={setStep} />
            ) : step === 14 ? (
                <PolicyAgreement appData={appData} setLoading={setLoading} setStep={setStep} />
            ) : step === 15 ? (
                <CompletionPage appData={appData} setLoading={setLoading} setStep={setStep} />
            ) : null}
        </div>
    )
}