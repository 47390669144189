import React, { useRef, useState, useEffect } from 'react';
import { Document, Page } from "react-pdf";
import SignatureCanvas from 'react-signature-canvas';

export default props => {
    const { apiUrl } = window['runConfig'];
    const [path, setPath] = useState("");
    const appid = props.appData.applicantId;
    useEffect(() => {
        props.setLoading(true);
        fetch(`${apiUrl}/api/Career/GetPDFPath`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ path: appid + '-2020-Form-W-4.pdf' })
        }).then(response => {
            return response.json()
        }).then(response => {
            if (response.success) {
                setPath(response.pdfStream);
            }
            props.setLoading(false);
        }).catch(err => {
            props.setLoading(false);
            console.log(err);
        })
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div>
            <div className="form">
                <h3>W-4 Form</h3>
                <br />
                <iframe
                    id="wPDF"
                    title="W-4"
                    src={`data:application/pdf;base64,` + path}
                    frameBorder={0}
                    scrolling="no"
                    allowFullScreen
                    className="iFramePDF" />
                <input type="button" value="Back" onClick={() => props.setStep(10)} style={{ marginRight: "12px" }} />
                <input type="button" value="Next" onClick={() => props.setStep(12)} style={{ marginRight: "12px" }} />
            </div>
        </div>
    )
}