import React, { useRef, useState, useEffect } from 'react';
import { Document, Page } from "react-pdf";

export default props => {
    const { apiUrl } = window['runConfig'];
    const appData = props.appData;
    const [validationError, setValidationError] = useState(false);
    const [SignatureCheck, setSignatureCheck] = useState(false);
    const [path, setPath] = useState("");
    useEffect(() => {
        setSignatureCheck(appData.terminationSignatureApproved || false);
        props.setLoading(true);
        fetch(`${apiUrl}/api/Career/GetTemplatePDFPath`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ path: 'Employee_Termination.pdf' })
        }).then(response => {
            return response.json()
        }).then(response => {
            if (response.success) {
                setPath(response.pdfStream);
            }
            props.setLoading(false);
        }).catch(err => {
            props.setLoading(false);
            console.log(err);
        })
    }, [appData])
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const next = () => {
        if (!SignatureCheck) {
            setValidationError(true);
            props.setLoading(false);
            return;
        } else {
            setValidationError(false);
        }
        if (props.appData.terminationSignatureApproved) {
            props.setStep(6);
        }
        const request = {
            key: localStorage.getItem("applicantKey") || '',
            signatureApproved: SignatureCheck
        }
        fetch(`${apiUrl}/api/Career/SignTerminationForm`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then(response => {
            return response.json()
        }).then(response => {
            if (response.success) {
                props.setStep(8)
            }
            props.setLoading(false);
        }).catch(err => {
            console.error(err);
            props.setLoading(false);
        })
    }
    return (
        <div>
            <div className="form">
                <h3>The document below is an example termination checklist.</h3>
                <br />
                <iframe
                    id="iPDF"
                    title="TerminationPDF"
                    src={`data:application/pdf;base64,` + path}
                    frameBorder={0}
                    scrolling="no"
                    allowFullScreen
                    className="iFramePDF"/>
                <div className="formrow">
                    <label>Check to sign:  <input type="checkbox" checked={SignatureCheck} onChange={(e) => setSignatureCheck(SignatureCheck ? false : true)} /></label>
                    {validationError ? (
                        <div>You must appprove this.</div>
                    ) : null}
                </div>
                <input type="button" value="Back" onClick={() => props.setStep(6)} style={{ marginRight: "12px" }} />
                <input type="button" value="Next" onClick={() => next()} style={{ marginRight: "12px" }} />
            </div>
        </div>
    )
}