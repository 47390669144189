import React, { useState, useEffect, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import "../styles/form.css";

export default props => {
    const [validationError, setValidationError] = useState(false);
    const [option, setOption] = useState(1);
    const [check, setCheck] = useState("");
    const [QualifyingChildren, setQualifyingChildren] = useState([])
    const [Dependants, setDependants] = useState([])
    const [DependantTotal, setDependantTotal] = useState([])
    const [OtherIncome, setOtherIncome] = useState("");
    const [Deductions, setDeductions] = useState("");
    const [Extra, setExtra] = useState("");
    const [SignatureCheck, setSignatureCheck] = useState(false);
    const { apiUrl } = window['runConfig'];
    const appData = props.appData;
    useEffect(() => {
        setOption(appData.w4Option || '');
        setCheck(appData.w4Checkbox || false);
        setQualifyingChildren(appData.qualifyingChildren || '');
        setDependants(appData.dependants || '');
        setDependantTotal(appData.dependantTotal || '');
        setOtherIncome(appData.otherIncome || '');
        setDeductions(appData.deductions || '');
        setExtra(appData.w4Extras || '');
        setSignatureCheck(appData.w4SignatureApproved || false);
    }, [appData])
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const calculateDependant = () => {
        setDependantTotal(Number(QualifyingChildren) + Number(Dependants));
    }
    const submit = (e) => {
        e.preventDefault();
        props.setLoading(true);
        if (!SignatureCheck) {
            setValidationError(true);
            props.setLoading(false);
            return;
        } else {
            setValidationError(false);
        }
        if (props.appData.w4SignatureApproved) {
            props.setStep(11);
        }
        const request = {
            key: localStorage.getItem("applicantKey") || '',
            signatureApproved: SignatureCheck,
            option: option,
            check: check,
            QualifyingChildren: QualifyingChildren,
            Dependants: Dependants,
            DependantTotal: DependantTotal,
            OtherIncome: OtherIncome,
            Deductions: Deductions,
            Extra: Extra
        };
        fetch(`${apiUrl}/api/Career/FillW4PDF`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then(response => {
            return response.json()
        }).then(response => {
            if (response.success) {

                props.setStep(11);
            }
            props.setLoading(false);
        }).catch(err => {
            console.error(err);
            props.setLoading(false);
        })
    }
    return (
        <div>
            <form action="/" method="post" onSubmit={(e) => submit(e)}>
                <div className="form">
                    <label>
                        I am aware that federal law provides for imprisonment and/or fines for false statements or use of false documents in connection with the completion of this form.
                    </label>
                    <div className="formrow">
                        <label>
                            I attest, under penalty of perjury, that I am (check one of the following):
                        </label>
                        <label><input type="radio" name="i9cit" checked={option === 1 ? true : false} onClick={(e) => setOption(1)} /> Single or Married filing separately</label>
                        <label><input type="radio" name="i9cit" checked={option === 2 ? true : false} onClick={(e) => setOption(2)} /> Married filing jointly (or Qualifying widow(er))</label>
                        <label><input type="radio" name="i9cit" checked={option === 3 ? true : false} onClick={(e) => setOption(3)} /> Head of household (Check only if you're unmarried and pay more than half the costs of keeping up a home for yourself and a qualifying individual.)</label>
                    </div>
                    <div className="formrow">
                        <label>Complete this step if you (1) hold more than one job at a time, or (2) are married filing jointly and your spouse also works.
                            The correct amount of withholding depends on income earned from all of these jobs.</label>
                        <label>Do only one of the following.</label>
                    </div>
                    <div className="formrow">
                        <label>(a)	Use the estimator at www.irs.gov/W4App or most accurate withholding for this step; <b>or</b></label>
                        <label>(b)	Use the Multiple Jobs Worksheet on page 3 and enter the result in Step 4(c) below for roughly accurate withholding; <b>or</b></label>
                    </div>
                    <div className="formrow">
                        <label>If there are only two jobs total, you may check this box. Do the same on Form W-4 for the other job.
                            This option is accurate for jobs with similar pay; otherwise, more tax than necessary may be withheld... </label>
                        <input type="checkbox" checked={check ? true : false} onChange={(e) => setCheck(check ? false : true)} />
                    </div>
                    <label>Complete Steps 3-4(b) on Form W-4 for only ONE of these jobs. Leave those steps blank for the other jobs.
                        (Your withholding will be most accurate if you complete Steps 3-4(b) on the Form W-4 for the highest paying job.)</label>
                    <div className="formrow">
                        <label>If your income will be $200,000 or less ($400,000 or less if married filing jointly):</label>
                    </div>
                    <div className="formrow">
                        <label>Multiply the number of qualifying children under age 17 by $2,000... </label>
                        <input type="text" value={QualifyingChildren} onChange={(e) => setQualifyingChildren(e.target.value)} onBlur={(e) => calculateDependant()} />
                    </div>
                    <div className="formrow">
                        <label>Multiply the number of other dependents by $500... </label>
                        <input type="text" value={Dependants} onChange={(e) => setDependants(e.target.value)} onBlur={(e) => calculateDependant()} />
                    </div>
                    <div className="formrow">
                        <label>Add the amounts above and enter the total here... </label>
                        <input type="text" value={DependantTotal} disabled />
                    </div>
                    <label>Other Adjustments (optional):</label>
                    <div className="formrow">
                        <label>
                            (a) Other income (not from jobs). If you want tax withheld for other income you expect
                            this year that won't have withholding, enter the amount of other income here. This may
                            include interest, dividends, and retirement income... 
                        </label>
                        <input type="text" value={OtherIncome} onChange={(e) => setOtherIncome(e.target.value)} />
                    </div>
                    <div className="formrow">
                        <label>
                            (b) Deductions. If you expect to claim deductions other than the standard deduction
                            and want to reduce your withholding, use the Deductions Worksheet on page 3 and
                            enter the result here... 
                        </label>
                        <input type="text" value={Deductions} onChange={(e) => setDeductions(e.target.value)} />
                    </div>
                    <div className="formrow">
                        <label>
                            (c)  Extra withholding. Enter any additional tax you want withheld each pay period... 
                        </label>
                        <input type="text" value={Extra} onChange={(e) => setExtra(e.target.value)} />
                    </div>
                    <div className="formrow">
                        <label>Check to sign:  <input type="checkbox" checked={SignatureCheck} onChange={(e) => setSignatureCheck(SignatureCheck ? false : true)} /></label>
                        {validationError ? (
                            <div>You must appprove this.</div>
                        ) : null}
                    </div>
                    <input type="button" value="Back" onClick={() => props.setStep(9)} style={{ marginRight: "12px" }} />
                    <input type="submit" value="Next" style={{ marginRight: "12px" }} />
                </div>
            </form>
        </div>
    )
}