import React, { useState, useEffect } from 'react'
import "../styles/form.css";

export default props => {
    const setLoading = props.setLoading;
    const { apiUrl } = window['runConfig'];
    const { csemUrl } = window['runConfig'];
    const { orgId } = window["runConfig"];
    const appData = props.appData;
    const [pass, setPass] = useState("");
    const [username, setUsername] = useState("");
    const [succeeded, setSucceeded] = useState(false);
    const [message, setMessage] = useState("");
    let appkey = localStorage.getItem("applicantKey");
    const req = {
        appKey: appkey
    }
    const bindReq = {
        key: appkey
    }
    useEffect(() => {
        setLoading(true);
        fetch(`${apiUrl}/api/Career/CompleteApp`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req)
        }).then(response => {
            return response.json();
        }).then(response => {
            const app = {
                username: appData.firstName.substring(0, 1) + appData.lastName,
                email: appData.email,
                password: (appData.firstName.substring(0, 1) + appData.lastName) + appData.ssn.substring(3, 9),
                orgID: orgId
            }
            setUsername(app.username);
            setPass(app.password);
            fetch(`${csemUrl}/api/Registration/RemoteRegisterUserAnon`, {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(app)
            }).then(response => {
                return response.json();
            }).then(response => {
                props.setLoading(false);
                setSucceeded(response.success);
                setMessage(response.message);
            }).catch(err => {
                props.setLoading(false);
                console.log(err);
            })
        }).catch(err => {
            props.setLoading(false);
        })
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div>
            <h3>Thank you for Completing the Application</h3>
            <h4>We will get back to you shortly</h4>
            <div>
                <label>If you would like to review your submission; you can either click back or return.</label>
            </div>
            {succeeded ?
                <div>
                    <div>
                        <label>With the information provided, we created a profile for you on CSEM's LMS. Please follow <a href={csemUrl + "/Login"} target="_blank"><u>[this Link]</u></a> to go to the LMS and complete
                        the competency Test assigned to you on your dashboard. Username and password for the site is found below:</label>
                    </div>
                    <div>
                        <label>Username: <b>{username}</b></label>
                        <br />
                        <label>Password: <b>{pass}</b></label>
                    </div>
                </div>
                : 
                <div>
                    <div>
                        <label>We attempted to create an account for you on CSEM-LMS, but failed due to these reasons:</label><br />
                        <label className="required">{message}</label>
                    </div>
                    <div>
                        <label>Please go to <a href={csemUrl + "/Login"} target="_blank"><u>[CSEM-LMS]</u></a> and create an account for yourself on there.</label>
                    </div>
                </div>
            }
            <div>
                <input type="button" value="Back" onClick={() => props.setStep(14)} style={{ marginRight: "12px" }} />
                <input type="button" value="Return" onClick={() => props.setStep(1)} style={{ marginRight: "12px" }} />
            </div>
        </div>
    )
}