import React, { useState, useEffect } from 'react';
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";

export default props => {
    const { apiUrl } = window['runConfig'];
    const [Files, setFiles] = useState([]);
    const [Files0, setFiles0] = useState([]);
    const [Files1, setFiles1] = useState([]);
    const [Files2, setFiles2] = useState([]);
    const [Files3, setFiles3] = useState([]);
    const [Files4, setFiles4] = useState([]);
    const [Files5, setFiles5] = useState([]);
    const [Files6, setFiles6] = useState([]);
    const [Files7, setFiles7] = useState([]);
    const [FileType, setFileType] = useState(0);
    const key = localStorage.getItem("applicantKey") || '';
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const uploadFiles = {
        url: `${apiUrl}/api/Career`,
        process: {
            url: "/UploadFiles",
            method: "post",
            body: JSON.stringify({ Files: Files }),
            onload: response => {
                const result = JSON.parse(response);
                if (!result.success) {
                    props.setLoading(false);
                    return;
                }
                assigning(result.attachmentId);
            }
        },
        load: null,
        revert: {
            url: "/RemoveAttachment",
            method: "post",
            onload: response => {
                const result = JSON.parse(response);
                if (!result.success) {
                    props.setLoading(false);
                    return;
                }
                assigning(result.attachmentId);
            }
        },
        remove: null,
        restore: null,
        fetch: null,
        patch: null
    }
    const assigning = (id) => {
        const request = {
            attachId: id,
            fileType: FileType,
            key: key
        }
        fetch(`${apiUrl}/api/Career/AssignIdToAttachment`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        })
    }
    return (
        <div className="welcome">
            <p style={{fontWeight: "bolder"}}>Ageless Senior in Home Care, Inc.</p>
            <p>888-772-2739</p>
            <p>Fax: 724-478-2488</p>
            <p>Email: team@inhomecareexperts.com</p>
            <p>
            Hello! Thank you for your interest in joining Ageless Senior in Home Care. Enclosed in this booklet, you will find information that will need to be completed before your first shift of work. But we also wanted to give you the details of the job!
            What we have to offer you:
            </p>
            <ul>
                <li>Competitive wages</li>
                <li>Merit rewards</li>
                <li>Incentive programs</li>
                <li>Room for growth within our company</li>
            </ul>
            <p>
            What you need to complete (within 7 days of hire):
            </p>
            <ul>
                <li>
                    Form of Photo ID
                    <ul>
                        <li>Driver's License</li>
                        <li>School ID</li>
                        <li>Passport</li>
                        <li>Visa</li>
                    </ul>
                </li>
                <li>
                    2 Step series Tuberculosis Test
                </li>
                <li>
                    Direct Deposit Information
                    <ul>
                        <li>Voided check</li>
                        <li>Paper with Bank letterhead: PRINTED Routing and Account Number</li>
                        <li>
                            Net-Spend, ADP, Repaid, etc.<br />
                            Must have a screenshot of routing and account number
                        </li>
                    </ul>
                </li>
                <li>
                    Car Insurance
                </li>
                <li>
                    Two-year proof of PA Residency
                    <ul>
                        <li>Old W-2 form</li>
                        <li>Older utility bill, car insurance, leasing agreement, etc.</li>
                        <li>Driver's license IF has exactly 2 years or more on the Issued Date</li>
                    </ul>
                </li>
                <li>
                    Competency Test (
                        <a href="http://services.dpw.state.pa.us/pch_comptest" target="_blank">
                            http://services.dpw.state.pa.us/pch_comptest
                        </a>)
                </li>
            </ul>
            <p>
            You cannot be placed on a schedule until these items are completed. Send your information over via email, fax, or picture text message.
            </p>
            <br />
            <strong>Upload Form of ID (year old at least):</strong>
            <FilePond
                name="idOneUpload"
                allowMultiple={false}
                acceptedFileTypes={['image/*']}
                server={uploadFiles}
                onupdatefiles={(files) => {
                    setFileType(0);
                    setFiles(files.map(file => file.file));
                }}
            />
            <br />
            <strong>Upload Image of TB Test:</strong>
            <FilePond
                name="tbTestUpload"
                allowMultiple={false}
                acceptedFileTypes={['image/*']}
                server={uploadFiles}
                onupdatefiles={(files) => {
                    setFileType(1);
                    setFiles(files.map(file => file.file));
                }}
            />
            <br />
            <strong>Upload Picture of Driver's License:</strong>
            <FilePond
                name="driverLicenseUpload"
                allowMultiple={false}
                acceptedFileTypes={['image/*']}
                server={uploadFiles}
                onupdatefiles={(files) => {
                    setFileType(2);
                    setFiles(files.map(file => file.file));
                }}
            />
            <br />
            <strong>Upload Picture of SS Card:</strong>
            <FilePond
                name="ssCardUpload"
                allowMultiple={false}
                acceptedFileTypes={['image/*']}
                server={uploadFiles}
                onupdatefiles={(files) => {
                    setFileType(3);
                    setFiles(files.map(file => file.file));
                }}
            />
            <br />
            <strong>Upload Picture Proof of Insurance:</strong>
            <FilePond
                name="insuranceUpload"
                allowMultiple={false}
                acceptedFileTypes={['image/*']}
                server={uploadFiles}
                onupdatefiles={(files) => {
                    setFileType(4);
                    setFiles(files.map(file => file.file));
                }}
            />
            <br />
            <strong>Upload Direct Deposit Information:</strong>
            <FilePond
                name="ddInfoUpload"
                allowMultiple={false}
                acceptedFileTypes={['image/*']}
                server={uploadFiles}
                onupdatefiles={(files) => {
                    setFileType(5);
                    setFiles(files.map(file => file.file));
                }}
            />
            <br />
            <strong>Upload a clear picture of yourself here: </strong>
            <FilePond
                name="selfPicUpload"
                allowMultiple={false}
                acceptedFileTypes={['image/*']}
                server={uploadFiles}
                onupdatefiles={(files) => {
                    setFileType(6);
                    setFiles(files.map(file => file.file));
                }}
            />
            <br />
            <strong>Finally, upload a clear picture of the Competency Test Certificate here: </strong>
            <FilePond
                name="completionUpload"
                allowMultiple={false}
                acceptedFileTypes={['image/*']}
                server={uploadFiles}
                onupdatefiles={(files) => {
                    setFileType(7);
                    setFiles(files.map(file => file.file));
                }}
            />
            <br />
            <input type="button" value="Back" onClick={() => props.setStep(1)} style={{marginRight: "12px"}} />
            <input type="button" value="Next" onClick={() => props.setStep(3)} />
        </div>
    )
}