import React, {useState, useEffect, useRef} from 'react'
import "../styles/form.css";
import SignatureCanvas from 'react-signature-canvas';

export default props => {
    const setLoading = props.setLoading;
    const appData = props.appData;
    const {apiUrl} = window['runConfig'];
    const [states, setStates] = useState([]);
    const [months] = useState([
        {name: "January", value: 1},
        {name: "February", value: 2},
        {name: "March", value: 3},
        {name: "April", value: 4},
        {name: "May", value: 5},
        {name: "June", value: 6},
        {name: "July", value: 7},
        {name: "August", value: 8},
        {name: "September", value: 9},
        {name: "October", value: 10},
        {name: "November", value: 11},
        {name: "December", value: 12}
    ]);
    const [locations, setLocations] = useState([])
    const [years, setYears] = useState([])
    const [days, setDays] = useState([])
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [middleInit, setMiddleInit] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [county, setCounty] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [ssn, setSsn] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [payRate, setPayrate] = useState(7.25);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [birthMonth, setBirthMonth] = useState(1);
    const [birthDay, setBirthDay] = useState(1);
    const [birthYear, setBirthYear] = useState(2020);
    const [jobPosition, setJobPosition] = useState(0);
    const [validationError, setValidationError] = useState(false);
    const [jobValidation, setJobValidation] = useState(false);
    const [locationvalidation, setLocationValidation] = useState(false);
    const [phoneValidation, setPhoneValidation] = useState(false);
    const [zipValidation, setZipValidation] = useState(false);
    const [ssnValidation, setSSNValidation] = useState(false);
    const [emailValidation, setEmailValidation] = useState(false);
    const [birthDate, setBirthDate] = useState(new Date());
    let sigPad = useRef(null);
    const sigChanged = () => {
        setValidationError(false);
    }
    useEffect(() => {
        setFirstName(appData.firstName || '');
        setMiddleInit(appData.middleInit || '');
        setLastName(appData.lastName || '');
        setAddress1(appData.address1 || '');
        setAddress2(appData.address2 || '');
        setCity(appData.city || '');
        setState(appData.state || '');
        setZip(appData.zipCode || '');
        setCounty(appData.county || '');
        setPhone(appData.phone || '');
        setEmail(appData.email || '');
        setSsn(appData.ssn || '');
        setBirthDay(appData.birthDay || 1);
        setBirthMonth(appData.birthMonth || 1);
        setBirthYear(appData.birthYear || 2020);
        setBirthDate(appData.birthDateFormatted || new Date());
        setStartDate(appData.startDateFormatted || new Date());
        setPayrate(appData.desiredPay || 7.25);
        setSelectedLocations(appData.locations || []);
        setJobPosition(appData.jobPosition || 0);
    }, [appData])
    useEffect(() => {
        setLoading(true);
        fetch("/states.json").then(response => {
            return response.json()
        }).then(states => {
            setStates(states);
            return fetch(`${apiUrl}/api/Career/GetLocations`, {
                method: "get",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
        }).then(response => {
            return response.json();
        }).then(response => {
            if (response.success)
            {
                setLocations(response.locations);
            }
            setLoading(false);
        }).catch(err => {
            console.error(err);
            setLoading(false);
        });

        let currentYear = new Date().getFullYear();
        let yearList = []
        for(var x = 0; x < 99; x++)
        {
            yearList.push(currentYear - x)
        }
        setYears(yearList);
    }, [apiUrl, setLoading]);
    useEffect(() => {
        setBirthDay(1);
        const month30days = [9, 4, 6, 11]
        const dayList = [];
        for (let d = 1; d < 30; d++)
        {
            dayList.push(d);
        }
        if (parseInt(birthMonth) === 2)
        {
            setDays(dayList);
            return;
        }
        dayList.push(30);
        if (month30days.includes(parseInt(birthMonth)))
        {
            setDays(dayList);
            return;
        }
        dayList.push(31);
        setDays(dayList);
    }, [birthMonth]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const isLocationSelected = location => {
        return selectedLocations ? 
            selectedLocations.filter(loc => loc === location).length > 0 :
            false
    }
    const changeLocationSelected = (checked, location) => {
        let newSelectedLocations = [...selectedLocations];
        if (checked)
        {
            newSelectedLocations.push(location);
            setSelectedLocations(newSelectedLocations);
            return
        }
        let idx = newSelectedLocations.indexOf(location);
        if (idx === -1)
            return
        newSelectedLocations.splice(idx, 1);
        setSelectedLocations(newSelectedLocations);
    }
    const submit = e => {
        e.preventDefault();
        props.setLoading(true);
        setPhoneValidation(false);
        setZipValidation(false);
        setEmailValidation(false);
        setSSNValidation(false);
        var sighold = "";
        if (jobPosition === 0 || jobPosition === "0") {
            setJobValidation(true);
            setLoading(false);
            return;
        } else {
            setJobValidation(false);
        }
        if (selectedLocations.length === 0) {
            setLocationValidation(true);
            setLoading(false);
            return;
        } else {
            setLocationValidation(false);
        }
        if (props.appData.jobDescriptionSignature) {
            sighold = appData.jobDescriptionSignature;
        }
        else {
            if (props.appData.jobDescriptionSignature) {
                props.setStep(5);
            }
            if (!sigPad.toData || sigPad.toData().length === 0) {
                setValidationError(true);
                props.setLoading(false);
                return;
            }
            sighold = sigPad.toDataURL();
        }
        if (!phone.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g)) {
            setPhoneValidation(true);
            setLoading(false);
            window.scrollTo(0, 0);
            return;
        }
        if (!zip.match(/^[0-9]{5}(?:-[0-9]{4})?$/g)) {
            setZipValidation(true);
            setLoading(false);
            window.scrollTo(0, 0);
            return;
        }
        if (!ssn.match(/^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$|^(?!(000|666|9))\d{3}(?!00)\d{2}(?!0000)\d{4}$/gi)) {
            setSSNValidation(true);
            setLoading(false);
            window.scrollTo(0, 0);
            return;
        }
        if (!email.match(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi)) {
            setEmailValidation(true);
            setLoading(false);
            window.scrollTo(0, 0);
            return;
        }
        const request = {
            key: localStorage.getItem("applicantKey") || '',
            firstName: firstName,
            middleInit: middleInit,
            lastName: lastName,
            address1: address1,
            address2: address2,
            city: city,
            state: state,
            zipCode: zip,
            county: county,
            phone: phone,
            email: email,
            birthDate: birthDate,
            ssn: ssn,
            desiredPay: 0,
            startDate: startDate,
            locations: selectedLocations,
            jobPosition: jobPosition,
            signature: sighold
        };
        fetch(`${apiUrl}/api/Career/SaveApplication`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then(response => {
            return response.json()
        }).then(response => {
            if (response.success)
            {
                localStorage.setItem("applicantKey", response.applicationKey);
                props.setStep(2);
                alert("You have 10 days to complete your application after starting.");
            }
            props.setLoading(false);
        }).catch(err =>{
            console.error(err);
            props.setLoading(false);
        })
    }
    const ClearSig = () => {
        sigPad.clear();
    }
    return (
        <div>
            <p>
                Thank you for your interest in starting a rewarding career with Ageless Senior In Home Care.
                To complete your application and onboarding paperwork, we will need to collect some information.  
                Please complete these forms to continue.  You may return to this page at any time to resume working on your application.
            </p>
            <form action="/" method="post" onSubmit={e => submit(e)}>
                <div className="form">
                    <div className="formrow">
                        <label>
                            First Name
                            <span className="required">*</span>
                        </label>
                        <input type="text" value={firstName} 
                            onChange={e => setFirstName(e.target.value)} 
                            required />
                    </div>
                    <div className="formrow">
                        <label>
                            Middle Initial
                        </label>
                        <input type="text" value={middleInit} maxLength="1"
                            onChange={e => setMiddleInit(e.target.value)}/>
                    </div>
                    <div className="formrow">
                        <label>
                            Last Name
                            <span className="required">*</span>
                        </label>
                        <input type="text" value={lastName}
                            onChange={e => setLastName(e.target.value)} 
                            required />
                    </div>
                    <div className="formrow">
                        <label>
                            Street Address
                            <span className="required">*</span>
                        </label>
                        <input type="text" style={{width: "600px"}}
                            value={address1} onChange={e => setAddress1(e.target.value)} 
                            required />
                    </div>
                    <div className="formrow">
                        <input type="text" style={{width: "600px"}}
                            value={address2} onChange={e => setAddress2(e.target.value)} />
                    </div>
                    <div className="formrow">
                        <label>
                            City
                            <span className="required">*</span>
                        </label>
                        <input type="text" style={{width: "550px"}}
                            value={city} onChange={e => setCity(e.target.value)} />
                    </div>
                    <div className="formrow">
                        <label>
                            State
                            <span className="required">*</span>
                        </label>
                        <select value={state} onChange={e => setState(e.target.value)}>
                            <option value="">-- Select a state --</option>
                            {states.map(state => 
                                <option key={state.abbreviation} value={state.abbreviation}>
                                    {state.name}
                                </option>
                            )}
                        </select>
                    </div>
                    <div className="formrow">
                        <label>
                            Zip Code
                            <span className="required">*</span>
                        </label>
                        <input type="text" maxLength="10" style={{width: "220px"}}
                            value={zip} onChange={e => setZip(e.target.value)}
                            required />
                        {zipValidation ?
                            <label className="required">A valid Zip Code is needed.</label>
                            : null}
                    </div>
                    <div className="formrow">
                        <label>
                            County
                            <span className="required">*</span>
                        </label>
                        <input type="text" name="county"
                            value={county} onChange={e => setCounty(e.target.value)}
                            required />
                    </div>
                    <div className="formrow">
                        <label>
                            Phone Number
                            <span className="required">*</span>
                        </label>
                        <input type="text" value={phone} 
                            onChange={e => setPhone(e.target.value)}
                            required />
                        {phoneValidation ?
                            <label className="required">A valid phone number is needed. XXX-XXX-XXXX</label>
                            : null}
                    </div>
                    <div className="formrow">
                        <label>
                            Email Address
                            <span className="required">*</span>
                        </label>
                        <input type="text" style={{width: "450px"}}
                            value={email} onChange={e => setEmail(e.target.value)}
                            required />
                        {emailValidation ?
                            <label className="required">A valid email address is needed.</label>
                            : null}
                    </div>
                    <div className="formrow">
                        <label>
                            Birthdate
                            <span className="required">*</span>
                        </label>
                        <input type="date" value={birthDate}
                            onChange={e => setBirthDate(e.target.value)}
                            required />
                    </div>
                    <div className="formrow">
                        <label>
                            SSN
                            <span className="required">*</span>
                        </label>
                        <input type="text" maxLength="11" placeholder="XXX-XX-XXXX"
                            style={{width: "220px"}} value={ssn}
                            onChange={e => setSsn(e.target.value)} required />
                        {ssnValidation ?
                            <label className="required">A valid SSN is needed. XXX-XX-XXXX.</label>
                            : null}
                    </div>
                    <div className="formrow">
                        <label>
                            Earliest Start Date
                            <span className="required">*</span>
                        </label>
                        <input type="date" value={startDate} 
                            onChange={e => setStartDate(e.target.value)} 
                            required />
                    </div>
                    <div className="formrow">
                        <label>
                            Desired Pay Rate
                        </label>
                        <input type="text" name="payRate"
                            value="TBD (To Be Determined)" disabled
                            required />
                    </div>
                    <div className="formrow">
                        <label>
                            Job Position
                            <span className="required">*</span>
                        </label>
                        <select value={jobPosition} onChange={e => setJobPosition(e.target.value)} required >
                            <option value={0}>-- Select a Job Position --</option>
                            <option key={1} value={1}>HHA (Home health aid)</option>
                            <option key={2} value={2}>Area Manager</option>
                            <option key={3} value={3}>Other... </option>
                        </select>
                        {jobValidation ? ( 
                            <div><br />
                                <label className="required">Please pick a job position!</label>
                            <br /></div>
                            ) : null }
                    </div>
                    <div className="formrow">
                        <label>
                            Location of Position(s) Applying For
                            <span className="required">*</span>
                        </label>
                        <div className="multiselect">
                            {locations.map(location =>
                                <label key={location.locationId} className="checkboxcontainer">
                                    <input type="checkbox" checked={isLocationSelected(location.locationId)}
                                        onChange={e => changeLocationSelected(e.target.checked, location.locationId)} />
                                    <span className="checkbox" />
                                    {location.locationName}
                                </label>
                            )}
                        </div>
                        {locationvalidation ? (
                            <div><br />
                                <label className="required">Please pick a location!</label>
                            <br /></div>
                        ) : null}
                    </div>
                    <div className="formrow">
                        <label>Employee Signature:</label>
                        {!props.appData.jobDescriptionSignature || props.appData.jobDescriptionSignature === "" ? (
                            <div>
                                <SignatureCanvas canvasProps={{ className: "signature" }} ref={ref => sigPad = ref} onEnd={() => sigChanged()} required clearOnResize={false} />
                                {validationError ? (
                                    <div><span className="required">Signature Required.</span></div>
                                ) : null}
                                <div><input type="button" onClick={() => ClearSig()} value="Clear" /></div>
                            </div>
                        ) : (
                                <div>
                                    <img src={props.appData.jobDescriptionSignature} alt="" height="200px" width="200px" />
                                </div>
                            )}
                    </div>
                    <div className="formrow">
                        <label>Date:</label>
                        {!props.appData.jobDescriptionSignature || props.appData.jobDescriptionSignature === "" ? (
                            <div>
                                {new Date().toDateString()}
                            </div>
                        ) : (
                                <div className="signature">
                                    {props.appData.jobDescriptionSignatureDate}
                                </div>
                            )}
                    </div>
                    <input type="submit" value="Next" />
                </div>
            </form>
        </div>
    )
}