import React, { useState, useEffect, useRef } from 'react';
import "../styles/form.css";
import SignatureCanvas from 'react-signature-canvas';

export default props => {
    const { apiUrl } = window['runConfig'];
    const appData = props.appData;
    const [validationError, setValidationError] = useState(false);
    const [SignatureCheck, setSignatureCheck] = useState(false);
    useEffect(() => {
        setSignatureCheck(appData.agreementSignatureApproved || false);
    }, [appData])
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const submit = (e) => {
        e.preventDefault();
        props.setLoading(true);
        if (!SignatureCheck) {
            setValidationError(true);
            props.setLoading(false);
            return;
        } else {
            setValidationError(false);
        }
        if (props.appData.agreementSignatureApproved) {
            props.setStep(13);
        }
        const request = {
            key: localStorage.getItem("applicantKey") || '',
            signatureApproved: SignatureCheck
        }
        fetch(`${apiUrl}/api/Career/SignEmployeeAgreement`, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request)
        }).then(response => {
            return response.json()
        }).then(response => {
            if (response.success) {
                props.setStep(13);
            }
            props.setLoading(false);
        }).catch(err => {
            console.error(err);
            props.setLoading(false);
        })
    }
    return (
        <div>
            <h3>As a new hired employee by Ageless Senior in Home Care, Inc, I agree to the following:</h3>
            <ul>
                <li>I will abide by the policies and procedures set forth by ASIHC, Inc.</li>
                <li>I agree not to hold ASIHC, Inc responsible for any injury that was incurred from a
                    pre-existing injury or any condition related to a pre-existing injury.</li>
                <li>I understand my employment is based on participant needs and ASIHC, Inc
                    cannot guarantee continued employment on this basis.</li>
                <li>I understand the Participant timesheets, Telephony call in and out must be
                    completed to determine my pay. 1-866-729-9720 - Telephony System</li>
                <li>I also understand these timesheets must be submitted into the office by Sunday
                    at 5:00PM of each week. Telephony system is to be used every day for clocking
                    in and out, unless technical difficulties arise.</li>
                <li>I understand a Participant may request I be removed from a job in their home
                    and the Agency cannot guarantee me another job.</li>
                <li>I understand I may be terminated upon receiving numerous complaints from the
                    participant about my work performance or attitude.</li>
                <li>I understand I may be terminated upon receiving numerous complaints from the
                    participant about my work performance or attitude.</li>
                <li>I understand if I am frequently late, call off, or do not show up for a scheduled
                    shift, I will be reviewed for possible termination.</li>
                <li>I agree not to accept employment privately from any Participant in which I gained
                    knowledge of through ASIHC, Inc. if I do, I agree to pay ASIHC, Inc the sum
                    specified in the company policies for damages incurred by my actions.</li>
            </ul>
            <form action="/" method="post" onSubmit={(e) => submit(e)}>
                <label>I have read and understand the above statements.</label>
                <div className="formrow">
                    <label>Check to sign:  <input type="checkbox" checked={SignatureCheck} onChange={(e) => setSignatureCheck(SignatureCheck ? false : true)} /></label>
                    {validationError ? (
                        <div>You must appprove this.</div>
                    ) : null}
                </div>
                <input type="button" value="Back" onClick={() => props.setStep(11)} style={{ marginRight: "12px" }} />
                <input type="submit" value="Next" style={{ marginRight: "12px" }} />
            </form>
        </div>
    )
}